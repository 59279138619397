/** @jsxImportSource theme-ui */
import { Flex, Text, Box } from 'theme-ui'
import { MdAdd, MdRemove } from 'react-icons/md'
import { ComponentProps } from 'react'

export const QuantitySelector = ({
  quantity,
  onChangeQuantity,
  className,
}: {
  quantity: number
  onChangeQuantity: (n: number) => void
  className?: ComponentProps<typeof Box>['className']
}) => {
  return (
    <Flex sx={{ justifyContent: 'flex-start' }}>
      <Flex
        sx={{
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: 'border',
          justifyContent: 'flex-start',
        }}
        className={className}
      >
        <Flex
          as="button"
          sx={{
            paddingX: 1,
            alignItems: 'center',
            justifyContent: 'center',
            variant: 'styles.button',
          }}
          role="Decrement Quantity"
          onClick={() => {
            onChangeQuantity(quantity - 1)
          }}
        >
          <MdRemove size={20} />
        </Flex>
        <Box>
          <Text
            sx={{
              paddingX: 2,
              paddingY: 1,
              appearance: 'none',
              border: 'none',
              outline: 'none',
              width: 'auto',
              fontSize: 2,
              fontWeight: 'bold',
            }}
          >
            {quantity}
          </Text>
        </Box>
        <Flex
          as="button"
          role="Increment Quantity"
          onClick={() => {
            onChangeQuantity(quantity + 1)
          }}
          sx={{
            paddingX: 1,
            alignItems: 'center',
            justifyContent: 'center',
            variant: 'styles.button',
          }}
        >
          <MdAdd size={20} />
        </Flex>
      </Flex>
    </Flex>
  )
}
