import React, {
  ReactNode,
  useState,
  useCallback,
  useContext,
  ComponentPropsWithoutRef,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
  useMemo,
} from 'react'
import Header from '../components/Header'
import defaultHeaderProps from '../components/Header/index'
import { Analytics } from 'src/api/analytics'

type Context = {
  headerHeight?: number
  mobileNavIsOpen: boolean
  toggleMobileNavIsOpen: (endState?: boolean) => void
  setHeaderHeight: (height: number) => void
  headerProps: Partial<ComponentPropsWithoutRef<typeof Header>>
  updateHeaderProps: (
    props: Partial<ComponentPropsWithoutRef<typeof Header>>
  ) => void
  toggleCartIsOpen: (endState?: boolean) => void
  cartIsOpen: boolean
  quickShopModalProductId: string | null
  openQuickShopModal: (productId: string) => void
  closeQuickShopModal: () => void
  openNavDropdownId: null | string
  setNavDropdownId: React.Dispatch<React.SetStateAction<string | null>>
}

export type UIContextValues = Context

// @ts-ignore
export const UIContext = React.createContext<Context>()

export const useMobileNav = (): [boolean, Context['toggleMobileNavIsOpen']] => {
  const { mobileNavIsOpen, toggleMobileNavIsOpen } = useContext(UIContext)

  return [mobileNavIsOpen, toggleMobileNavIsOpen]
}
export const useCartModal = () => {
  const { cartIsOpen, toggleCartIsOpen } = useContext(UIContext)
  return { cartIsOpen, toggleCartIsOpen }
}

export const useQuickShop = () => {
  const {
    quickShopModalProductId: productId,
    closeQuickShopModal: close,
    openQuickShopModal: open,
  } = useContext(UIContext)

  return { productId, close, open }
}

export const useNavDropdown = (): [
  string | null,
  Context['setNavDropdownId'],
] => {
  const { openNavDropdownId, setNavDropdownId } = useContext(UIContext)

  return [openNavDropdownId, setNavDropdownId]
}

export const useMeasurements = () => {
  const { headerHeight, setHeaderHeight } = useContext(UIContext)

  const headerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        setHeaderHeight(node.getBoundingClientRect().height)
      }
    },
    [setHeaderHeight]
  )

  return { headerHeight, headerRef }
}

/**
 * @deprecated
 * Sets the header's props. Only runs once when the page loads initally.
 */
export const useSetHeaderInitialProps = (
  props: Partial<ComponentPropsWithoutRef<typeof Header>>
) => {}

export const UIProvider = ({ children }: { children: ReactNode }) => {
  const [headerHeight, setHeaderHeight] = useState<number>(72)
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)
  const [cartIsOpen, setCartIsOpen] = useState(false)
  const [quickShopModalProductId, setQuickShopModalProductId] =
    useState<Context['quickShopModalProductId']>(null)
  const [openNavDropdownId, setNavDropdownId] = useState<null | string>(null)

  const closeQuickShopModal = useCallback(
    () => setQuickShopModalProductId(null),
    []
  )
  const openQuickShopModal = useCallback(
    (productId: string) => setQuickShopModalProductId(productId),
    []
  )

  const toggleMobileNavIsOpen = useCallback(
    (endState?: boolean) => setMobileNavIsOpen((state) => endState ?? !state),
    []
  )
  const toggleCartIsOpen = useCallback((endState?: boolean) => {
    setCartIsOpen((state) => {
      if (endState === true && !state) {
        window.analytics?.track(Analytics.events['Open Cart'])
      }
      return endState ?? !state
    })
  }, [])
  const [headerProps, setHeaderProps] = useState<
    ComponentPropsWithoutRef<typeof Header>
  >({})
  const updateHeaderProps = useCallback(
    (props: Partial<ComponentPropsWithoutRef<typeof Header>>) => {
      setHeaderProps((state) => ({ ...state, ...props }))
    },
    []
  )

  const value = useMemo(
    () => ({
      headerHeight,
      mobileNavIsOpen,
      toggleMobileNavIsOpen,
      setHeaderHeight,
      headerProps,
      updateHeaderProps,
      cartIsOpen,
      toggleCartIsOpen,
      quickShopModalProductId,
      closeQuickShopModal,
      openQuickShopModal,
      openNavDropdownId,
      setNavDropdownId,
    }),
    [
      cartIsOpen,
      closeQuickShopModal,
      headerHeight,
      headerProps,
      mobileNavIsOpen,
      openNavDropdownId,
      openQuickShopModal,
      quickShopModalProductId,
      toggleCartIsOpen,
      toggleMobileNavIsOpen,
      updateHeaderProps,
    ]
  )

  return (
    <UIContext.Provider
      // value={{
      //   headerHeight,
      //   mobileNavIsOpen,
      //   toggleMobileNavIsOpen,
      //   setHeaderHeight,
      //   headerProps,
      //   updateHeaderProps,
      //   cartIsOpen,
      //   toggleCartIsOpen,
      //   quickShopModalProductId,
      //   closeQuickShopModal,
      //   openQuickShopModal,
      //   openNavDropdownId,
      //   setNavDropdownId,
      // }}
      value={value}
    >
      {children}
    </UIContext.Provider>
  )
}
