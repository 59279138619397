/** @jsxImportSource theme-ui */
import { Button, Flex, Text, useThemeUI } from 'theme-ui'

import { useResponsiveValue } from '@theme-ui/match-media'
import * as React from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { useLockBodyScroll } from 'src/hooks/use-lock-body-scroll'
import { getPrice } from 'src/utils/price'
import { Box } from 'theme-ui'
import StoreContext from '../context/StoreContext'
import { useCartModal } from '../context/UIContext'
import { CartGamification } from './Cart-Gamification'
import CartLineItems from './Cart-Line-Items'

const CartModal = ({ cartFreeShipping }: { cartFreeShipping: any }) => {
  Modal.setAppElement('#penguin_app')

  const { cartIsOpen, toggleCartIsOpen } = useCartModal()
  const {
    theme: { colors },
  } = useThemeUI()
  const { openCheckout, openingCheckout, cart } = React.useContext(StoreContext)

  useLockBodyScroll({ lock: cartIsOpen })

  const AnimationDuration = 500

  const renderFooter = React.useMemo(() => {
    return (
      <Box bg={['muted']} p={4}>
        <Flex sx={{ justifyContent: 'space-between', mb: 2 }}>
          <Text sx={{}}>Subtotal</Text>
          <Text>{getPrice(`${cart?.cost.subtotalAmount.amount}`)}</Text>
        </Flex>
        <Button
          disabled={openingCheckout}
          onClick={openCheckout}
          sx={{ width: '100%' }}
        >
          {!openingCheckout ? 'Checkout' : 'Loading...'}
        </Button>
      </Box>
    )
  }, [openCheckout, openingCheckout, cart?.lines])

  const renderLineItems = React.useCallback(() => {
    return (
      <Box sx={{ flex: 1 }}>
        <CartLineItems cartFreeShipping={cartFreeShipping} />
      </Box>
    )
  }, [])
  const renderHeader = React.useCallback(() => {
    return (
      <Box sx={{ bg: ['muted'] }}>
        <Flex sx={{ alignItems: 'center' }}>
          <button
            onClick={(e) => {
              e.preventDefault()
              // // console.log('close')
              toggleCartIsOpen(false)
            }}
            sx={{
              appearance: 'none',
              bg: 'transparent',
              border: 'none',
              px: 3,
              alignItems: 'center',
              cursor: 'pointer',
              flex: 0.5,
              outline: 'none',
            }}
            aria-label="Website Menu"
          >
            <Flex sx={{ p: 4, alignItems: 'center' }}>
              <MdClose size={20} />
            </Flex>
          </button>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Text sx={{ textAlign: 'center' }}>
              Cart ({cart?.totalQuantity})
            </Text>
          </Box>
          <Box sx={{ flex: 0.5 }}></Box>
        </Flex>
      </Box>
    )
  }, [toggleCartIsOpen, cart?.totalQuantity])

  // const height = useResponsiveValue(['auto', '100%'])
  const transform = useResponsiveValue(['translateY(100%)', 'translateX(100%)'])

  return (
    <React.Fragment>
      <Modal
        isOpen={cartIsOpen}
        onRequestClose={() => toggleCartIsOpen(false)}
        closeTimeoutMS={AnimationDuration}
        style={{
          overlay: {
            position: `fixed`,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: `#00000050`,
            zIndex: 1100,
            outline: 'none',
          },
          content: {
            position: `absolute`,
            border: `none`,
            background: `none`,
            padding: 0,
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            overflow: 'auto',
            WebkitOverflowScrolling: `touch`,
            outline: 'none',
          },
        }}
        className={{
          afterOpen: 'CartModal__Overlay--after-open',
          base: 'CartModal__Overlay',
          beforeClose: 'CartModal__Overlay--before-close',
        }}
      >
        <Flex
          onClick={() => toggleCartIsOpen(false)}
          sx={{
            //   display: `flex`,
            position: `relative`,
            height: '100%',
            justifyContent: 'flex-end',
            flexDirection: ['column', 'row'],
            p: 2,
          }}
        >
          <Flex
            sx={{
              height: ['auto', '100%'],
              bg: 'background',
              width: [null, '100%'],
              maxWidth: [null, 9],
              mx: [2, 0],
              mb: [2, 0],
              borderRadius: [3],
              maxHeight: (t) => [`calc(100% - ${t.space[4]})`, '100%'],
              overflow: 'hidden',
              flexDirection: 'column',
            }}
          >
            {renderHeader()}
            <CartGamification cartFreeShipping={cartFreeShipping} />
            <Box
              onClick={(e) => e.stopPropagation()}
              // onMouseDown={e => e.stopPropagation()}
              sx={{ flexDirection: 'column', flex: 1, overflowY: 'scroll' }}
            >
              {renderLineItems()}
            </Box>
            <Box
              // onClickCapture={e => e.preventDefault()}
              onClick={(e) => e.stopPropagation()}
              // onMouseDown={e => e.stopPropagation()}
            >
              {renderFooter}
            </Box>
          </Flex>
        </Flex>
      </Modal>
      <style>{`
        .CartModal__Overlay {
          transform: ${transform};
          transition: all ${AnimationDuration}ms ease-in-out;
          will-change: transform;
          opacity: 0;
        }

        .CartModal__Overlay--after-open {
          opacity: 1;
          transform: translateX(0%) translateY(0%);
        }

        .CartModal__Overlay--before-close {
          opacity: 0;
          transform: ${transform};
        }
      `}</style>
    </React.Fragment>
  )
}

export default React.memo(CartModal)
