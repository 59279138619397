import { useResponsiveValue } from '@theme-ui/match-media'

export const HeaderHeight = [64, 64]

export const useCurrentHeaderHeight = () => {
  return useResponsiveValue(HeaderHeight)
}

/**
 * Function to generate a react "key" prop for a given link list item.
 */
export const getHeaderLinkId = (info: { url: string; text: string }) =>
  info.url + info.text

export type LinkListItem = {
  url: string
  name: string
  dropdown?: {
    title: string
    links: {
      image: {
        url: string
        height: number
        width: number
      }
      text: string
      url: string
    }[]
  }
  customDropdown?: {
    title: string
    links: {
      image: {
        url: string
        height: number
        width: number
      }
      text: string
      url: string
    }[]
  }
  customDropdownList?: {
    title: string,
    sys:{
      id: string
    }
  }[]
}

export type HeaderQuery = {
  contentfulMainHeaderSettings: {
    handle: string
    leftMenuLinkList: {
      title: string
      links: LinkListItem[]
    }
    rightMenuLinkList: {
      title: string
      links: LinkListItem[]
    }
    mobileLinkList: {
      title: string
      links: LinkListItem[]
    }
  }
}
