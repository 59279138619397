/** @jsxImportSource theme-ui */
import { memo } from 'react'
import { useTheme } from 'src/hooks/use-theme'
import { Box, Flex, Text } from 'theme-ui'
import { SmartLink } from '~/features/contentful-sections/Smart-Link'
import { ContentfulImage } from '~/features/image/contentful-image'
import { LinkListItem } from '../Header/types'

type Props = Required<LinkListItem>['dropdown']['links'][0] & {
  index: number
  totalLength: number
  onClick: (url: string) => void
  isOpen: boolean
}

function DropdownItem(props: Props) {
  const { text, image, url, index, totalLength, onClick, isOpen } = props
  const { colors } = useTheme()
  let width = ['50%', '25%']

  if (index + 1 === totalLength && totalLength % 2 === 1) {
    // if there are an odd number of links, and this is the last one
    width = ['100%', '20%']
  }

  return (
    <Box sx={{ width: ["50%","250px"], flexShrink:0 }}>
      <Box
        sx={{
          margin: 2,
          position: 'relative',
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <SmartLink
          role={`Link to ${text}`}
          aria-roledescription={text}
          to={url}
          onClick={() => onClick(url)}
          forceExternalLink={url.includes('account')}
          sx={{
            textDecoration: 'none',
          }}
        >
          {!!isOpen && (
            <Box sx={{ position: 'relative', height: ['100px', '150px'] }}>
              <ContentfulImage
                alt={`Penguin CBD ${text}`}
                src={image.url}
                fill
                sizes="200px"
                sx={{objectFit:'cover'}}
              />
            </Box>
          )}
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              inset: 0,
              background: `${colors.text}30`,
              transition: 'background 0.25s ease',
              willChange: 'background',
              '&:hover, &:active': {
                background: 'transparent',
              },
            }}
          >
            <Text
              sx={{
                fontWeight: 'bold',
                color: 'background',
                textAlign: 'center',
                lineHeight: 1.25,
                fontSize: [4, 5],
              }}
            >
              {text}
            </Text>
          </Flex>
        </SmartLink>
      </Box>
    </Box>
  )
}

export default memo(DropdownItem)
