import { ComponentProps } from 'react'
import React from 'react'
import Link from 'next/link'

type Props = ComponentProps<typeof Link> & {
  forceExternalLink?: boolean
}

export const SmartLink = function SmartLink(
  props: Omit<Props, 'href'> & {
    to: string
    activeClassName?: string
    partiallyActive?: boolean
  }
) {
  const {
    to,
    children,
    activeClassName,
    partiallyActive,
    forceExternalLink = false,
    ...rest
  } = props

  const isLocal = to.startsWith('/')

  if (isLocal && !forceExternalLink)
    return (
      <Link
        href={props.to}
        {...(props.to.startsWith('/account') && { prefetch: false })}
        {...rest}
      >
        {children}
      </Link>
    )

  return (
    <a
      {...rest}
      href={to}
      aria-label={to}
      rel={!isLocal ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  )
}
