/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react'
import { useLayoutEffect, useEffect } from 'react'

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollLocation(
  effect: (info: { currPos: { y: number } }) => void,
  deps = [] as any[],
  element = false,
  useWindow = false,
  wait = null
) {
  const position = useRef(null as any as ReturnType<typeof getScrollPosition>)
  if (!position.current) {
    position.current = getScrollPosition({ element, useWindow })
  }

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

useScrollLocation.defaultProps = {
  deps: [],
  element: false,
  useWindow: false,
  wait: null,
}
