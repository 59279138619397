import { useCallback, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'

export const useEmailSubscribe = () => {
  const [email, setEmail] = useState('')
  const [ready, setReady] = useState(false)
  const { execute, loading, error } = useAsyncCallback(async(email: string) =>
    await fetch(`/api/shopify/customer/email-subscribe`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      }).then(res=>res.json())
  )

  const onSubmit = useCallback(
    async e => {
      e.preventDefault()
      // // console.log('[use-email-auth] submitted!', { email })
      if (!email) return

      const response = await execute(email)
      setReady(response.success)
    },
    [execute, email]
  )

  return {
    onSubmit,
    email,
    loading,
    networkError: error,
    setEmail,
    ready,
    error,
  }
}
