/** @jsxImportSource theme-ui */
import { useRouter } from 'next/router'
import React from 'react'
import {
  FaCcAmex,
  FaCcDiscover,
  FaCcMastercard,
  FaCcVisa,
} from 'react-icons/fa'
import { ShopName } from 'src/constants'
import { useTheme } from 'src/hooks/use-theme'
import { Box, Container, Flex, Text } from 'theme-ui'
import { useGlobalAppProps } from '~/api/GlobalAppPropsContext'
import { SmartLink } from '~/features/contentful-sections/Smart-Link'
import { SubscribeSection } from '~/features/contentful-sections/SubscribeSection'
import { ContentfulImage } from '~/features/image/contentful-image'
import Logo from './Logo'

function Foot() {
  const theme = useTheme()
  const { footerSettings } = useGlobalAppProps() || {}

  const { asPath: href } = useRouter()

  if (!footerSettings) return null
  const { disclaimer, disclaimerTitle, socialLinksCollection, showSubscribeSection, subscribeSection  } = footerSettings

  const linkLists = footerSettings.linkListsCollection.items

  const bg = theme.colors.primary
  const color = theme.colors.background

  const titleProps = {
    sx: { color: `${color}98`, mb: 3, fontSize: 2 },
    variant: 'text.spacedOut',
  }

  const renderSocialLinks = () => {
    // // console.log('[footer]', { socialLinks })
    return (
      <Box
        sx={{
          mx: 3,
          my: [7, 0],
          flex: [null, 1],
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, justifyContent:["center", null] }}>
          {socialLinksCollection.items?.map(({ logo, socialName, url }) => {
            if (!url || !logo) return null

            return (
              <a
                aria-label={`${ShopName} ${socialName}`}
                href={url}
                target="_blank"
                key={url}
                sx={{
                  display: 'inline-block',
                  px: 3,
                  width: 40,
                  height: 40,
                  position: 'relative',
                }}
                rel="noreferrer noopener"
              >
                <ContentfulImage
                  sizes="(min-width: 1120px) 40px, (min-width: 780px) calc(4.06vw - 5px), 40px"
                  fill
                  sx={{ width: '25px', display: 'inline-block' }}
                  alt={`${ShopName} ${socialName}`}
                  src={logo.url}
                />
              </a>
            )
          })}
        </Box>
        <Box sx={{ display: 'flex', gap: 2, marginTop: 16, justifyContent:["center", null] }}>
          <FaCcVisa sx={{ fontSize: 40 }} />
          <FaCcMastercard sx={{ fontSize: 40 }} />
          <FaCcDiscover sx={{ fontSize: 40 }} />
          <FaCcAmex sx={{ fontSize: 40 }} />
        </Box>
      </Box>
    )
  }

  const renderLinkLists = () => {
    return linkLists.map(({ linksCollection, title }, i) => {
      return (
        <Box
          sx={{
            ml: i > 0 ? [null, 4] : undefined,
            mx: 3,
            my: [5, 0],
            flex: [null, 1],
          }}
          key={title}
        >
          {/* <Text {...titleProps}>{title}</Text> */}
          {linksCollection.items?.map(
            ({ url, name }) =>
              url && (
                <Box sx={{ pb: 2 }} key={`${name}-${url}`}>
                  <SmartLink
                    sx={{
                      color,
                      textDecoration: 'none',
                      // fontSize: 5,
                      transition: 'border-bottom .25s ease-in-out',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 2,
                      borderBottomColor: 'transparent',
                      '&:hover, &:active': {
                        borderBottomColor: 'inherit',
                      },
                    }}
                    to={url}
                  >
                    {name}
                  </SmartLink>
                </Box>
              )
          )}
        </Box>
      )
    })
  }

  const renderDisclaimer = () => {
    return (
      <Box sx={{ px: 3, mt: [7], fontSize: '14px' }}>
        {/* <Text {...titleProps}>{disclaimerTitle}</Text> */}
        <Text sx={{ color: `${color}90` }}>{disclaimer}</Text>
      </Box>
    )
  }

  return (
    <>
    {showSubscribeSection&&<SubscribeSection {...subscribeSection} />}
    <Box
      sx={{
        bg,
        color,
        pt: [5, 7],
        pb: href?.includes('/products') ? ['7rem'] : ["3rem", 7],
        textAlign: ['center', 'left'],
      }}
    >
      <Container>
        <Flex sx={{ display: ['block', 'flex'] }}>
          <Box sx={{ flex: [null, 1], px: 3 }}>
            <SmartLink to="/">
              <Logo
                white={[color === theme.colors.background]}
                sx={{
                  mb: [5, 8],
                  mx: ['auto', '0'],
                  px: [3, 0],
                  // width: ['50%', '20%!important'],
                  width: ['50%', '80%!important'],
                  display: 'block',
                  height: 'auto!important',
                  maxWidth: 200,
                }}
              />
            </SmartLink>
          </Box>
          {renderLinkLists()}
          {renderSocialLinks()}
        </Flex>
        <Box sx={{ flex: [null, 1] }}>{renderDisclaimer()}</Box>
      </Container>
    </Box>
    </>
  )
}

export const Footer = React.memo(Foot)
