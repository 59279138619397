export const Analytics = {
  events: {
    // https://segment.com/docs/connections/spec/ecommerce/v2/
    // these map to segment ecommerce events
    'Open Cart': 'Cart Viewed',
    'Add To Cart': 'Product Added',
    Checkout: 'Checkout Started',
    'Remove From Cart': 'Product Removed',
    'Update Cart Quantity': 'Update Cart Quantity',
    'Product Viewed': 'Product Viewed',
    'Product Clicked': 'Product Clicked',
    'Product List Viewed': 'Product List Viewed',
  },
}
