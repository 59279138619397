/** @jsxImportSource theme-ui */

import Image from 'next/image'
import React, { useCallback, useContext } from 'react'
import { MdClose } from 'react-icons/md'
import { CartLine } from 'shopify-buy'
import { Box, Flex, Text } from 'theme-ui'
import StoreContext from '../context/StoreContext'
import { getPrice } from '../utils/price'
import { LoadingOverlay } from './Loading-Overlay'
import { QuantitySelector } from './Quantity-Selector'

type Props = {
  isUpdating: boolean
  isFreeItem: boolean
} & CartLine

const LineItem = (props: Props) => {
  const {
    title,
    id: merchandiseId,
    image,
    selectedOptions,
    product,
    compareAtPrice,
  } = props.merchandise
  const { amount: price } = props.cost.amountPerQuantity
  const {
    sellingPlanAllocation,
    id: lineId,
    isUpdating,
    quantity,
    isFreeItem,
  } = props

  const { updateLineItem, isUpdatingLineItem, removeLineItem } =
    useContext(StoreContext)

  const renderLeft = useCallback(() => {
    return (
      <Flex sx={{ flex: 1, alignItems: 'center' }} marginLeft={2}>
        <Box sx={{ mr: 2, size: 100, position: 'relative' }}>
          {!!image?.width && (
            <Image
              sx={{
                objectFit: 'cover',
              }}
              alt={product.title}
              fill
              // query images w/ 300 width
              src={image?.url?.replace('.jpg', '_x100.jpg') + '&em-format=webp'}
            />
          )}
        </Box>
        <Box paddingY={2}>
          <Text sx={{ fontWeight: 'bold' }}>{product.title}</Text>
          <Box>
            <Text sx={{ color: 'gray' }}>
              {selectedOptions?.map(({ value }) => value).join(' | ')}
            </Text>
          </Box>
          {sellingPlanAllocation && (
            <Box>
              <Text sx={{ color: 'gray' }}>
                {sellingPlanAllocation.sellingPlan.name}
              </Text>
            </Box>
          )}
          {!isFreeItem && (
            <QuantitySelector
              quantity={quantity}
              onChangeQuantity={(quantity) => {
                if (quantity) updateLineItem(lineId, merchandiseId, quantity)
                else removeLineItem(lineId)
                // // console.log('[line-item] update', { id, quantity })
              }}
            />
          )}
        </Box>
      </Flex>
    )
  }, [
    merchandiseId,
    image,
    quantity,
    removeLineItem,
    title,
    updateLineItem,
    selectedOptions,
  ])

  const renderRight = useCallback(() => {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
        marginY={2}
        marginRight={2}
      >
        <Box
          as="button"
          sx={{ variant: 'styles.button' }}
          onClick={() => removeLineItem(lineId)}
        >
          <MdClose size={20} />
        </Box>
        {compareAtPrice && compareAtPrice?.amount && compareAtPrice?.amount != price && (
          <Text
            sx={{
              color: 'gray',
              textDecoration: 'line-through',
              textDecorationColor: 'red',
              textDecorationThickness: '2px',
            }}
          >
            {getPrice(compareAtPrice?.amount)}
          </Text>
        )}
        <Text sx={{ color: 'gray' }}>{getPrice(price)}</Text>
      </Flex>
    )
  }, [lineId, price, removeLineItem])

  const renderLoadingOverlay = useCallback(() => {
    if (!isUpdating) return null
    return <LoadingOverlay />
    // return (
    //   <Flex
    //     sx={{
    //       position: 'absolute',
    //       top: 0,
    //       left: 0,
    //       right: 0,
    //       bottom: 0,
    //       bg: '#ffffff50',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <Spinner />
    //   </Flex>
    // )
  }, [isUpdating])

  return (
    <Box
      sx={{
        position: 'relative',
        marginBottom: 2,
        paddingBottom: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: 'border',
        borderBottomWidth: 1,
      }}
      // onClick={() => removeLineItem(id)}
    >
      {renderLoadingOverlay()}
      <Flex>
        {renderLeft()}
        {renderRight()}
      </Flex>
    </Box>
  )
}

export default React.memo(LineItem)
