/** @jsxImportSource theme-ui */
import { useBreakpointIndex, useResponsiveValue } from '@theme-ui/match-media'
import Link from 'next/link'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MdExpandMore, MdMenu } from 'react-icons/md'
import { useScrollLocation } from 'src/hooks/use-scroll-location'
import { Box, Flex, useThemeUI } from 'theme-ui'
import { GlobalAppProps } from '~/api/services'
import {
  useCartModal,
  useMobileNav,
  useNavDropdown,
} from '../../context/UIContext'
import Logo from '../Logo'
import Dropdown from '../Nav-Drawer/Dropdown'
import OpenCartButton from '../Open-Cart-Button'
import { HeaderHeight, getHeaderLinkId } from './types'

const headerScrolled = {
  backgroundColor: 'background',
  boxShadow: 'header',
  color: 'text',
} as const
const header = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  color: 'background',
  transition: 'all .15s ease-in-out',
} as const

type Props = {
  initiallyScrolled?: boolean
  scrollDistance?: number
  sticky?: boolean
  leftTintColor?: string | string[]
  rightTintColor?: string | string[]
  whiteLogo?: (boolean | 'Yes' | 'No')[]
  message?: string
  messageDisplayRule: 'Both' | 'Desktop' | 'Mobile'
  messageBackgroundColor?: { colorArray?: string[] }
  messageTextColor?: { colorArray?: string[] }
  contentfulMainHeaderSettings?: GlobalAppProps['mainHeaderSettings']
}

export const defaultHeaderProps = {
  sticky: false,
  initiallyScrolled: false,
  whiteLogo: [true],
  leftTintColor: ['background'],
  rightTintColor: ['background'],
  // message: 'FREE SHIPPING on all US orders 🎉',
  message: '',
  messageBackgroundColor: { colorArray: ['text'] },
  messageDisplayRule: 'Both',
  messageTextColor: { colorArray: ['background'] },
} satisfies Partial<Props>

function Header(props: Props) {
  const {
    sticky = defaultHeaderProps.sticky,
    initiallyScrolled = sticky,
    scrollDistance = 50,
    leftTintColor = defaultHeaderProps.leftTintColor,
    rightTintColor = defaultHeaderProps.rightTintColor,
    whiteLogo = defaultHeaderProps.whiteLogo,
    message = defaultHeaderProps.message,
    messageTextColor = defaultHeaderProps.messageTextColor,
    messageDisplayRule = defaultHeaderProps.messageDisplayRule,
    messageBackgroundColor = defaultHeaderProps.messageBackgroundColor,
    contentfulMainHeaderSettings,
  } = props
  const rightMenuLinkList = contentfulMainHeaderSettings?.rightMenuLinkList
  const leftMenuLinkList = contentfulMainHeaderSettings?.leftMenuLinkList
  const [hasScrolled, setHasScrolled] = useState(initiallyScrolled)
  const rightIconColor = useResponsiveValue(
    Array.isArray(rightTintColor) ? rightTintColor : [rightTintColor],
    {
      defaultIndex: 0,
    }
  )
  const leftIconColor = useResponsiveValue(
    Array.isArray(leftTintColor) ? leftTintColor : [leftTintColor],
    {
      defaultIndex: 0,
    }
  )
  const breakpointIndex = useBreakpointIndex()
  // const isWhiteLogo = whiteLogo?.map(white =>
  //   white === 'Yes' ? true : white === 'No' ? false : white
  // )

  const {
    theme: { colors },
  } = useThemeUI()

  const { toggleCartIsOpen } = useCartModal()
  const toggleMobileNavIsOpen = useMobileNav()[1]

  useEffect(() => {
    setHasScrolled(initiallyScrolled)
  }, [initiallyScrolled])

  const hasScrolledRef = useRef(hasScrolled)
  useEffect(() => {
    hasScrolledRef.current = hasScrolled
  }, [hasScrolled])

  useScrollLocation(
    ({ currPos }) => {
      if (sticky) return

      const isScrolled = Math.abs(currPos.y) > scrollDistance

      if (hasScrolledRef.current !== isScrolled) setHasScrolled(isScrolled)
    },
    [sticky]
  )

  // const { scrollY } = useViewportScroll()

  const [dropdownId, setDropdownId] = useNavDropdown()

  const dropdownMenu = useMemo(
    () =>
      dropdownId
        ? [
            ...(leftMenuLinkList?.linksCollection.items ?? []),
            ...(rightMenuLinkList?.linksCollection.items ?? []),
          ].find(
            ({ url, name }) =>
              getHeaderLinkId({ url, text: name }) === dropdownId
          )
        : null,
    [dropdownId, leftMenuLinkList, rightMenuLinkList]
  )
  const showScrolledStyle =
    hasScrolled ||
    sticky ||
    dropdownMenu?.dropdown?.linksCollection?.items.length

  const renderLinkList = useCallback(
    (
      links: GlobalAppProps['mainHeaderSettings']['leftMenuLinkList']['linksCollection']['items'],
      sx: object,
      iconColor?: string
    ) => {
      return links.map((link, i) => {
        const As =
          link.url.startsWith('http') || link.url.startsWith('account')
            ? 'a'
            : link.dropdown
              ? Box
              : Link
        return (
          <Box sx={{ position: 'relative' }} key={i}>
            <As
              // don't prefetch account, to avoid firebase etc.
              sx={{
                ...sx,
                paddingRight: link.dropdown ? '4px' : 0,
                alignItems: 'center',
                cursor: 'pointer',
              }}
              href={link.dropdown ? undefined : (link.url as any)}
              onClick={(e) => {
                if (link.dropdown) {
                  e.preventDefault()
                  setDropdownId((currentId) => {
                    const clickedId = getHeaderLinkId({
                      url: link.url,
                      text: link.name,
                    })
                    if (currentId === clickedId) {
                      // close the dropdown
                      return null
                    }
                    return clickedId
                  })
                } else {
                  setDropdownId(null)
                }
              }}
            >
              <>
                <span>{link.name}</span>
                {!!link.dropdown && (
                  <Box
                    sx={{ position: 'absolute', right: '-2px', bottom: '1px' }}
                  >
                    <MdExpandMore size={20} color={iconColor} />
                  </Box>
                )}
              </>
            </As>
          </Box>
        )
      })
    },
    [setDropdownId]
  )

  const left = useMemo(() => {
    const color = showScrolledStyle
      ? colors?.text
      : leftIconColor
        ? (colors?.[leftIconColor] as string)
        : undefined
    return (
      <Flex
        sx={{
          display: 'flex',
          flex: [0.5, 0.75],
          alignItems: 'center',
        }}
      >
        <Flex sx={{ display: ['none', 'flex'], flex: 1, paddingLeft: 3 }}>
          {renderLinkList(
            leftMenuLinkList?.linksCollection.items ?? [],
            {
              variant: 'links.nav',
              color: !showScrolledStyle ? leftTintColor : undefined,
            },
            color
          )}
        </Flex>
        <button
          sx={{
            display: ['flex', 'none'],
            px: 3,
            variant: 'styles.button',
          }}
          aria-label="Open Website Menu"
          onClick={() => toggleMobileNavIsOpen(true)}
        >
          <MdMenu size={25} color={color} />
        </button>
      </Flex>
    )
  }, [
    colors,
    leftIconColor,
    leftMenuLinkList,
    leftTintColor,
    renderLinkList,
    showScrolledStyle,
    toggleMobileNavIsOpen,
  ])

  const center = useMemo(() => {
    return (
      <Flex sx={{ flex: [0.5, 0.3], justifyContent: ['center'] }}>
        <Link
          onClick={() => setDropdownId(null)}
          aria-label="Home"
          sx={{ display: 'flex' }}
          href="/"
        >
          <Logo
            loading="eager"
            priority
            white={
              !showScrolledStyle
                ? whiteLogo?.map((white) =>
                    white === 'Yes' ? true : white === 'No' ? false : white
                  )
                : undefined
            }
          />
        </Link>
      </Flex>
    )
  }, [setDropdownId, showScrolledStyle, whiteLogo])

  const right = useMemo(() => {
    return (
      <Flex
        sx={{
          flex: [0.5, 0.75],
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: ['none', 'flex'] }}>
          {renderLinkList(rightMenuLinkList?.linksCollection?.items ?? [], {
            variant: 'links.nav',
            color: !showScrolledStyle ? rightTintColor : undefined,
            // display: 'flex',
            // alignItems: 'flex-start',
          })}
        </Box>
        <OpenCartButton
          color={
            showScrolledStyle
              ? colors?.text
              : rightIconColor
                ? (colors?.[rightIconColor] as string)
                : undefined
          }
        />
        {/* <button
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            appearance: 'none',
            bg: 'transparent',
            border: 'none',
            px: 3,
          }}
          onClick={() => {
            // alert('cart opened')
            toggleCartIsOpen(true)
          }}
          aria-label="Shopping Cart"
        >
          <MdShoppingCart
            size={25}
            color={
              showScrolledStyle
                ? colors?.text
                : rightIconColor
                ? (colors?.[rightIconColor] as string)
                : undefined
            }
          />
        </button> */}
      </Flex>
    )
  }, [
    colors,
    renderLinkList,
    rightIconColor,
    rightMenuLinkList,
    rightTintColor,
    showScrolledStyle,
  ])

  const closeDropdown = useCallback(() => {
    setDropdownId(null)
  }, [setDropdownId])

  return (
    <Box
      as="header"
      sx={{
        ...header,
        ...(showScrolledStyle && headerScrolled),

        ...(sticky && { position: 'sticky' }),
      }}
    >
      {!!message && (
        <Box
          sx={{
            padding: 2,
            bg: messageBackgroundColor?.colorArray,
            display:
              messageDisplayRule == 'Both'
                ? ['block']
                : messageDisplayRule == 'Desktop'
                  ? ['none', 'block']
                  : ['block', 'none'],
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              fontWeight: '100',
              lineHeight: 1.1,
              color: messageTextColor?.colorArray,
              fontSize: [1, 2],
            }}
          >
            {message}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          height: [HeaderHeight[0], HeaderHeight[1]],
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '100%',
          }}
        >
          <Flex
            sx={{
              variant: 'styles.headerWrapper',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {left}
            {center}
            {right}
          </Flex>
          {dropdownMenu && (
            <Dropdown
              mobileHidden
              isOpen={
                getHeaderLinkId({
                  url: dropdownMenu?.url,
                  text: dropdownMenu?.name,
                }) === dropdownId
              }
              dropdown={dropdownMenu?.dropdown}
              customDropdown={dropdownMenu?.customDropdown}
              onClickLink={closeDropdown}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default memo(Header)
