// thanks to vikram / launch darkly for guidance here
// https://github.com/launchdarkly/LaunchDarkly-Docs/blob/master/src/gatsby-plugin-theme-ui/index.ts

const AbsoluteFill = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
} as const

const headerScrolled = {
  backgroundColor: 'background',
  boxShadow: 'header',
  color: 'text',
} as const
const header = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  color: 'background',
  transition: 'all .15s ease-in-out',
} as const

const button = {
  px: 7,
  py: 2,
  fontSize: 3,
  // borderRadius: 6,
  borderStyle: 'solid',
  borderWidth: '3px',
  fontWeight: 'semibold',
  transition: 'all .15s ease',
  willChange: 'background-color, color',
  cursor: 'pointer',
  borderColor: 'primary',
  '&:hover, &:active': {
    bg: 'background',
    color: 'primary',
  },
  borderRadius: 999,
  outline: 'none',
  appearance: 'none',
} as const

const ThemeUi = {
  // Adds styles defined in theme.styles.root to the <body> element along with color and background-color
  // https://theme-ui.com/theming/#configuration-flags
  // useBodyStyles: false,
  // breakpoints are intentionally set using em after some research and discussion based on this
  // https://zellwk.com/blog/media-query-units/
  breakpoints: ['48rem', '76rem', '88rem', '102rem'],
  fonts: {
    body: 'var(--font-sofia),-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    heading: 'inherit',
  },
  sizes: {
    0: '.75rem',
    1: '1rem',
    2: '1.25rem',
    3: '1.5rem',
    4: '2rem',
    5: '4rem',
    6: '8rem',
    7: '16rem',
    8: '24rem',
    9: '32rem',
    10: '40rem',
    11: '48rem',
    container: '66rem',
  },
  layout: {
    // container: { maxWidth: '60rem' },
  },
  fontSizes: [
    // '0.5rem',
    '0.625rem',
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3.5rem',
    '4.5rem',
  ],
  space: {
    0: 0,
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.5rem',
    6: '2rem',
    7: '3rem',
    8: '3.5rem',
    9: '4rem',
    10: '8rem',
  },
  fontWeights: {
    normal: 400,
    body: 400,
    medium: 500,
    semibold: 600,
    heading: 700,
    bold: 700,
  },
  borderWidths: [
    '0',
    '.0625rem',
    '.125rem',
    '.2rem',
    '.25rem',
    '.5rem',
    '1rem',
    '2rem',
  ],
  radii: [0, 4, 6, 8],
  lineHeights: {
    fixed: '1',
    spaced: '1.5',
    xsmall: '1rem',
    small: '1.25rem',
    regular: '1.5rem',
    medium: '2rem',
    large: '3rem',
    heading: '1.5em',
  },
  shadows: {
    header: 'rgba(0, 0, 0, 0.09) 0px 2px 24px 0px', // Header shadow
  },
  colors: {
    primary: 'black',
    secondary: '#11B3EB',
    accent: '#F1564B',
    // heading: '#263645',
    heading: '#000000',
    background: '#ffffff',
    text: '#000000',
    // muted: '#f7f7f7',
    // accent: '#025f9c',
    // border: '#bfbfbf',

    gray: '#4C626C',
    // 🙃
    grey: '#4C626C',

    // hims border
    border: '#D7D7D1',
    // hims muted
    muted: '#FBF8F5',
    mutedSecondary: '#e6f1f4',

    // allbirds muted
    // muted: '#F5F5F5',
  },
  cards: {
    collectionProduct: {
      position: 'relative',
      textAlign: 'center',
      '& > *': {
        zIndex: 2,
        bg: 'background',
      },
      '&:after': {
        ...AbsoluteFill,
        boxShadow: 'header',
        opacity: 0,
        transition: 'opacity .25s ease',
        // content: '""',
        zIndex: 0,
      },
      '&:hover': {
        '&:after': {
          opacity: 1,
        },
      },
    },
  },
  buttons: {
    primary: {
      ...button,
    },
    small: {
      ...button,
      px: button.px - 1,
      py: button.py / 2,
    },
    secondary: {
      ...button,
      // edit px here
      color: 'background',
      bg: 'secondary',
      borderColor: 'secondary',
      '&:hover': {
        bg: 'transparent',
        color: 'secondary',
      },
    },
    outline: {
      ...button,
      bg: 'transparent',
      color: 'primary',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        borderColor: 'primary',
      },
    },
    outlineBackground: {
      ...button,
      // bg: 'transparent',
      color: 'background',
      borderColor: 'background',
      '&:hover': {
        bg: 'background',
        color: 'text',
        borderColor: 'text',
      },
    },
    pill: {
      ...button,
      color: 'text',
      borderColor: 'text',
      bg: 'background',
      '&:hover': {
        bg: 'text',
        color: 'background',
        borderColor: 'background',
      },
      borderRadius: 999,
    },
    pillOutline: {
      ...button,
      bg: 'transparent',
      color: 'primary',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        borderColor: 'primary',
      },
      borderRadius: 999,
    },
    accent: {
      ...button,
      bg: 'accent',
      color: 'background',
      '&:hover': {
        bg: 'accent',
        color: 'background',
        borderColor: 'accent',
      },
    },
    underline: {
      pb: 1,
      px: 0,
      pt: 0,
      variant: 'text.spacedOut',
      fontWeight: 'bold',
      fontSize: 1,
      borderBottomStyle: 'solid',
      borderWidth: 2,
      background: 'none',
      color: 'inherit',
      borderRadius: 0,
      borderBottomColor: 'inherit',
      cursor: 'pointer',
    },
    radio: {
      borderWidth: 1,
      borderStyle: 'solid',
      py: 1,
      px: 2,
      mr: 1,
      fontSize: 1,
      // fontWeight: 'bold',
      borderRadius: 0,
      backgroundColor: 'transparent',
      color: 'primary',
      transition: 'all .25s ease',
      cursor: 'pointer',
      '&:hover': {
        color: 'background',
        borderColor: 'primary',
        backgroundColor: 'primary',
      },
      outline: 'none',
    },
  },
  links: {
    nav: {
      variant: 'text.spacedOut',
      mx: 3,
      py: 1,
      // textTransform: 'uppercase',
      // letterSpacing: '0.1em',
      fontSize: 1,
      fontWeight: 'bold',
      textDecoration: 'none',
      color: 'inherit',
      borderBottom: 'solid',
      borderBottomWidth: 2,
      borderColor: 'transparent',
      transition: 'border-color .25s ease',
      '&:hover': {
        borderBottomColor: 'inherit',
      },
    },
    default: {
      color: 'inherit',
    },
  },
  text: {
    spacedOut: {
      textTransform: 'uppercase',
      letterSpacing: '.125em',
      fontSize: 1,
      // fontSize: 0,
    },
    heading: {
      color: 'heading',
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      // mb: 2,
      mt: 0,
    },
    preHeader: {
      color: 'accent',
      display: 'block',
      // fontWeight: 'bold',
      // fontSize: 2,
      // variant: 'text.spacedOut',
    },
  },
  styles: {
    h1: {
      variant: 'text.heading',
      // m: 0,
      // mb: 3,
      fontSize: 6,
      // mt: 2,
      mb: 4,
    },
    h2: {
      variant: 'text.heading',
      // m: 0,
      mb: 4,
      fontSize: 5,
      // mt: 2,
    },
    h3: {
      variant: 'text.heading',
      m: 0,
      mb: 1,
      fontSize: 4,
      mt: 3,
    },
    h4: {
      variant: 'text.heading',
      m: 0,
      mb: 1,
      fontSize: 3,
    },
    h5: {
      variant: 'text.heading',
      m: 0,
      mb: 1,
      fontSize: 2,
    },
    h6: {
      variant: 'text.heading',
      m: 0,
      mb: 2,
      fontSize: 1,
    },
    div: {
      position: 'relative',
    },
    header,
    headerScrolled: {
      ...header,
      ...headerScrolled,
    },
    headerWrapper: {
      // py: [1, 3],
      // px: 3,
      alignItems: 'center',
    },
    navMenu: {},
    img: {
      margin: 0,
    },
    logo: { width: [125], height: 'auto', margin: 0 },
    imageWithTextOverlayContainer: {
      position: 'relative',
      p: 0,
    },
    p: {
      p: 0,
      margin: 0,
      marginBottom: 5,
    },
    a: {
      color: 'inherit',
    },
    gradient: {
      background: 'linear-gradient(#e4f5fb, #ffffff)',
    },
    button: {
      appearance: 'none',
      bg: 'transparent',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
    },
  },
  badges: {
    primary: {
      color: 'background',
      bg: 'primary',
      pt: '4px',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
      pt: '4px',
    },
  },
} as const

export default ThemeUi
