/** @jsxImportSource theme-ui */
import { useBreakpointIndex } from '@theme-ui/match-media'
import { Fragment, memo, useCallback, useMemo } from 'react'
import { MdClose, MdExpandMore } from 'react-icons/md'
import { useMobileNav, useNavDropdown } from 'src/context/UIContext'
import { useLockBodyScroll } from 'src/hooks/use-lock-body-scroll'
import { Box, Flex, Text } from 'theme-ui'
import { GlobalAppProps } from '~/api/services'
import { SmartLink } from '~/features/contentful-sections/Smart-Link'
import Dropdown from './Dropdown'

type Props = {
  links: GlobalAppProps['mainHeaderSettings']['mobileLinkList'] | undefined
  isOpen: boolean
  onClose: () => void
}

const NavDrawer = ({ links, isOpen, onClose }: Props) => {
  const [dropdownId, setDropdownId] = useNavDropdown()

  const renderHeader = useMemo(() => {
    return (
      <Box>
        <Flex sx={{ justifyContent: 'flex-end' }}>
          <button
            onClick={() => {
              onClose()
              setDropdownId(null)
            }}
            sx={{ variant: 'styles.button', padding: 3 }}
            aria-label="Close Menu"
          >
            <MdClose size={25} />
          </button>
        </Flex>
      </Box>
    )
  }, [onClose, setDropdownId])

  const renderLinks = useMemo(() => {
    return links?.linksCollection.items.map(
      ({ url, name, dropdown, customDropdown }, index) => {
        const slideTransitionDuration = 0.25
        const transitionDelay = index * 0.15 + slideTransitionDuration
        const id = `${url}${name}`

        const isItemOpen = id === dropdownId

        return (
          <Box
            sx={{
              // '&:hovered .dropdown': {
              //   visibility: [null, 'visible!important'],
              //   opacity: [null, `1!important`],
              //   display: [null, 'block'],
              // },
              borderBottomWidth:
                index + 1 === links.linksCollection.items.length ? 0 : 2,
              borderBottomStyle: 'solid',
              borderColor: 'border',
            }}
            key={url + name}
          >
            <Box>
              <SmartLink
                sx={{
                  color: 'accent',
                  display: 'block',
                  paddingX: 3,
                  paddingY: 2,
                  fontSize: 6,
                  appearance: 'none',
                  textDecoration: 'none',
                  variant: 'styles.a',
                  transition: `color ${slideTransitionDuration}s ease-in-out, transform 0.3s ${transitionDelay}s ease, opacity 0.3s ${transitionDelay}s ease`,
                  willChange: 'color, translateY, opacity',
                  // transform: isOpen ? 'translateY(0px)' : 'translateY(8px)',
                  // opacity: isOpen ? 1 : 0,
                  '&:hover': {
                    color: 'secondary',
                  },
                }}
                to={url}
                onClick={(e) => {
                  if (dropdown?.linksCollection.items.length) {
                    // if this is a button with dropdown links, don't open a window
                    // instead, opent he dropdown
                    e.preventDefault()
                    // if this dropdown is already selected, close it.
                    // otherwise, open it.
                    setDropdownId((currentDropdownId) => {
                      if (currentDropdownId === id) {
                        return null
                      }
                      return id
                    })
                  } else {
                    onClose()
                  }
                }}
              >
                <Flex>
                  <Box sx={{ flex: 1 }}>
                    <Text>{name}</Text>
                  </Box>
                  {!!dropdown?.linksCollection.items.length && (
                    <Flex
                      sx={{
                        alignItems: 'center',
                        transition: 'transform 0.25s ease',
                        transform: isItemOpen ? 'rotate(180deg)' : undefined,
                      }}
                    >
                      <MdExpandMore size={40} />
                    </Flex>
                  )}
                </Flex>
              </SmartLink>
              {!!dropdown?.linksCollection.items && (
                <Dropdown
                  onClickLink={(_) => {
                    setDropdownId(null)
                    onClose()
                  }}
                  dropdown={dropdown}
                  customDropdown={customDropdown}
                  isOpen={isItemOpen}
                />
              )}
            </Box>
          </Box>
        )
      }
    )
  }, [dropdownId, links, onClose, setDropdownId])

  // // console.log('[nav-drawer]', { links })

  return (
    <Fragment>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bg: 'muted',
          transition: 'transform 0.35s ease-in-out',
          transform: isOpen ? undefined : 'translateX(-100%)',
          display: [null, 'none'],
          zIndex: 100000000,
        }}
      >
        <Flex sx={{ flexDirection: 'column', height: '100%' }}>
          {renderHeader}
          <Box sx={{ flex: 1, overflowY: 'scroll' }}>{renderLinks}</Box>
        </Flex>
      </Box>
    </Fragment>
  )
}

export const SmartNavDrawer = memo(function SmartNavDrawer({
  links,
}: {
  links?: GlobalAppProps['mainHeaderSettings']['mobileLinkList']
}) {
  const [isOpen, toggleIsOpen] = useMobileNav()
  const close = useCallback(() => toggleIsOpen(false), [toggleIsOpen])
  const index = useBreakpointIndex()

  useLockBodyScroll({ lock: isOpen && index < 1 })

  return <NavDrawer isOpen={isOpen} onClose={close} links={links} />
})
