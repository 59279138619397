/* @jsxImportSource theme-ui */
import { useContext, useEffect, useState } from 'react'
import { CartLine } from 'shopify-buy'
import StoreContext from 'src/context/StoreContext'
import { Box, Flex, Text } from 'theme-ui'

type Props = {
  cartFreeShipping: {
    displayName: string
    items?: {
      references: {
        nodes: {
          price: {
            value: string
          }
          product: {
            value: string
            reference: {
              compareAtPrice?: string
              displayName: string
              price: string
              image: {
                altText?: string
                url: string
              }
              id: string
              title: string
              product: {
                title: string
                featuredImage: {
                  altText?: string
                  url: string
                }
                productType: string
                handle: string
              }
            }
          }
        }[]
      }
    }
    price: {
      value: string
    }
    freeShippingPrice: {
      value: string
    }
  }
}

function GamificationProgressBar({ cartFreeShipping }: Props) {
  const { addVariantToCart, removeLineItem } = useContext(StoreContext)
  const { cart } = useContext(StoreContext)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [progressText, setProgressText] = useState('')

  const total = cart?.cost.subtotalAmount.amount
  const cartLines = cart?.lines as any
  const freeShippingPrice =
    Number.parseFloat(cartFreeShipping.freeShippingPrice.value) || 75
  const shippingPrice = Number.parseFloat(cartFreeShipping.price.value) || 9.99

  let oldCart : any= null;

  let freeGiftArray = cartFreeShipping.items?.references.nodes.map((node) => {
    return {
      price: Number.parseFloat(node.price.value) || 0,
      product: {
        id: node.product.value,
        price: node.product.reference.price,
        compareAtPrice: node.product.reference.compareAtPrice,
        displayName: node.product.reference.displayName,
        title: node.product.reference.product.title,
        handle: node.product.reference.product.handle,
        productType: node.product.reference.product.productType,
        imageUrl:
          node.product.reference.image?.url ||
          node.product.reference.product.featuredImage.url,
      },
      variant: {
        id: node.product.reference.id,
        title: node.product.reference.title,
      },
    }
  })

  freeGiftArray = freeGiftArray?.toSorted((a, b) => a.price - b.price)

  const isGiftInCart = (itemId: string) => {
    for (let i = 0; i < cartLines.edges.length; i++) {
      const line = cartLines.edges[i].node as CartLine
      if (line.merchandise.id == itemId && (line.quantity || 0) >= 1)
        return true
    }
    return false
  }
  const removeGift = (itemId: string) => {
    for (let i = 0; i < cartLines.edges.length; i++) {
      const line = cartLines.edges[i].node as CartLine

      if (line.merchandise.id == itemId) {
        const lineId = line.id
        removeLineItem(lineId)
      }
    }
  }

  useEffect(() => {
    if(oldCart == cart)
      return;
    if (total !== undefined && total < freeShippingPrice) {
      setProgressPercentage(0)
      setProgressText(
        'You are $' +
          (freeShippingPrice - total).toFixed(2) +
          ' away from Free US Shipping'
      )
      freeGiftArray?.forEach((item) => {
        removeGift(item.product.id)
      })
    }

    if (freeGiftArray != undefined) {
      const topFreeGiftCount = freeGiftArray.length
      let prevPrice = freeShippingPrice
      for (let i = 0; i < freeGiftArray.length; i++) {
        const item = freeGiftArray[i]
        if (item == undefined) continue
        let curPrice = item?.price || 0
        if (total !== undefined && total >= prevPrice && total < curPrice) {
          setProgressPercentage(
            (i == 0 ? 10 : 0) + (100 / topFreeGiftCount) * i
          )
          setProgressText(
            'You are  $' +
              (curPrice - total).toFixed(2) +
              ' away from ' +
              item?.product.title
          )
          removeGift(item.product.id)
        }
        if (total !== undefined && total >= curPrice) {
          if (isGiftInCart(item?.product.id) == false) {
            const aimTrackObj = {
              id: item?.product.id,
              title: item?.product.title,
              handle: item?.product.handle,
              vendor: item?.product.productType,
              featuredImage: {
                url: item.product.imageUrl,
              },
              selectedVariant: {
                id: item.variant.id,
                title: item?.variant.title,
                price: {
                  amount: item.product?.price,
                  currencyCode: 'USD',
                },
                compareAtPrice: null,
                image: {
                  url: item.product.imageUrl,
                },
              },
            }

            window._aimTrack = window._aimTrack || []
            window._aimTrack.push(['aim_add_to_cart', aimTrackObj])
            addVariantToCart({
              variantId: item?.product.id,
              quantity: 1,
            })
          }
        }
        prevPrice = curPrice
      }
      const lastPrice = freeGiftArray[freeGiftArray.length - 1]?.price || 0
      if (total !== undefined && total >= lastPrice) {
        setProgressPercentage(100)
        setProgressText("Congratulations! you've unlocked all the gifts.")
      }
    }
    oldCart = cart;
  }, [cart])

  return (
    <Box mb={7} pt={0}>
      <Box
        sx={{
          textAlign: 'center',
          fontSize: '13px',
          marginBottom: '10px',
          textTransform: 'uppercase',
        }}
      >
        {progressText}
      </Box>

      <Flex
        sx={{
          width: '100%',
          height: '7px',
          bg: '#EBEBEB',
          position: 'relative',
          top: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: `${progressPercentage}%`,
            height: '100%',
            bg: '#10B2EB',
            position: 'absolute',
            left: 0,
            transition: 'width 0.5s ease-in-out',
          }}
        />
        <Box
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            position: 'relative',
            top: '-25px',
            transition: 'background-color 0.5s ease-in-out',
            backgroundColor: progressPercentage >= 10 ? '#10B2EB' : '#EBEBEB',
            filter:
              progressPercentage >= 10 ? 'grayscale(0%)' : 'grayscale(100%)',
            marginLeft: '2%',
            backgroundImage:
              'url("https://cdn.shopify.com/s/files/1/0247/8479/7778/files/fast-delivery.png?v=1678486990&em-format=webp")',
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            border: '5px solid',
            borderColor: progressPercentage >= 10 ? '#10B2EB' : '#EBEBEB',
          }}
        />
        {freeGiftArray?.map((item, index) => {
          const itemProgress =
            (index == 0 ? 10 : 0) + (100 / freeGiftArray.length) * index
          return (
            <Box
              key={item.product.id}
              sx={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                position: 'relative',
                top: '-25px',
                transition: 'background-color 0.5s ease-in-out',
                backgroundColor:
                  progressPercentage > itemProgress ? '#10B2EB' : '#EBEBEB',
                filter:
                  progressPercentage > itemProgress
                    ? 'grayscale(0%)'
                    : 'grayscale(100%)',
                backgroundImage: `url(${item.product.imageUrl}&em-format=webp&width=80)`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                border: '5px solid',
                borderColor:
                  progressPercentage > itemProgress ? '#10B2EB' : '#EBEBEB',
              }}
            />
          )
        })}
      </Flex>
      <Flex
        sx={{
          width: '100%',
          position: 'relative',
          top: '50px',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '2%',
            width: '60px',
          }}
        >
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: '10px',
              color: progressPercentage >= 10 ? '#999' : 'black',
              textDecoration:
                progressPercentage >= 10 ? 'line-through' : 'none',
            }}
          >
            Value ${shippingPrice}
          </Text>
          {progressPercentage >= 10 && (
            <Text
              sx={{
                fontWeight: 'bold',
                fontSize: '10px',
                color: 'black',
              }}
            >
              Free
            </Text>
          )}
        </Flex>
        {freeGiftArray?.map((item, index) => {
          const itemProgress =
            (index == 0 ? 10 : 0) + (100 / freeGiftArray.length) * index
          return (
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '60px',
              }}
              key={item.product.id}
            >
              <Text
                sx={{
                  fontSize: '9px',
                  fontWeight: 'bold',
                  color: progressPercentage > itemProgress ? '#999' : 'black',
                  textDecoration:
                    progressPercentage > itemProgress ? 'line-through' : 'none',
                }}
              >
                Value ${item.product.compareAtPrice || item.product.price}
              </Text>
              {progressPercentage > itemProgress && (
                <Text
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '10px',
                    color: 'black',
                  }}
                >
                  Free
                </Text>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Box>
  )
}

export function CartGamification({
  cartFreeShipping,
}: {
  cartFreeShipping: any
}) {
  return (
    <Box
      p={[2, 3]}
      bg="#FAF8F5"
      sx={{ position: 'relative', marginBottom: '10px' }}
    >
      <GamificationProgressBar cartFreeShipping={cartFreeShipping} />
    </Box>
  )
}
