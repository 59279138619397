import { useEffect } from 'react'

export function useLockBodyScroll({ lock }: { lock: boolean }) {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(
      document.documentElement
    ).overflow

    if (lock) {
      // Prevent scrolling on mount
      document.documentElement.style.overflow = 'hidden'
    }

    // Re-enable scrolling when component unmounts
    return () => {
      document.documentElement.style.overflow = originalStyle
    }
  }, [lock])
}
