import { createContext, useContext, useMemo } from 'react'
import type { withPageProps } from './services'

const GlobalAppPropsContext = createContext<Awaited<
  ReturnType<typeof withPageProps>
> | null>(null)

export function GlobalAppPropsProvider({
  children,
  props,
}: {
  children: any
  props: Awaited<ReturnType<typeof withPageProps>>
}) {
  const {
    allShopifyProduct,
    footerSettings,
    headerProps,
    mainHeaderSettings,
    allStampedReviewSummaries,
    productGridItems
  } = props
  return (
    <GlobalAppPropsContext.Provider
      value={useMemo(
        () => ({
          allShopifyProduct,
          footerSettings,
          headerProps,
          mainHeaderSettings,
          allStampedReviewSummaries,
          productGridItems
        }),
        [
          allShopifyProduct,
          footerSettings,
          headerProps,
          mainHeaderSettings,
          allStampedReviewSummaries,
          productGridItems
        ]
      )}
    >
      {children}
    </GlobalAppPropsContext.Provider>
  )
}

export function useGlobalAppProps() {
  return useContext(GlobalAppPropsContext)
}
