import React, { useContext } from 'react'
import StoreContext from '../context/StoreContext'
import LineItem from './Line-Item'
import { Box } from 'theme-ui'
import { Styled } from '~/components/styled'
import { CartLine } from 'shopify-buy'

type Props = {
  cartFreeShipping: {
    displayName: string
    items?: {
      references: {
        nodes: {
          price: {
            value: string
          }
          product: {
            value: string
            reference: {
              compareAtPrice?: string
              displayName: string
              price: string
              image: {
                altText?: string
                url: string
              }
              product: {
                title: string
                featuredImage: {
                  altText?: string
                  url: string
                }
              }
            }
          }
        }[]
      }
    }
    price: {
      value: string
    }
  }
}

const CartLineItems = ({ cartFreeShipping }: Props) => {
  const { cart, isUpdatingLineItem } = useContext(StoreContext)
  const lines: any = cart?.lines

  let freeGiftArray = cartFreeShipping.items?.references.nodes.map((node) => {
    return {
      price: Number.parseInt(node.price.value) || 0,
      product: {
        id: node.product.value,
        price: node.product.reference.price,
        compareAtPrice: node.product.reference.compareAtPrice,
        displayName: node.product.reference.displayName,
        title: node.product.reference.product.title,
        imageUrl:
          node.product.reference.image?.url ||
          node.product.reference.product.featuredImage.url,
      },
    }
  })

  const renderEmpty = () => {
    return (
      <Box sx={{ height: '100%', textAlign: 'center' }} m={2} mt={[2, 3, 4]}>
        <Styled.h2>Your cart is empty.</Styled.h2>
      </Box>
    )
  }

  return (
    <Box sx={{ height: '100%' }}>
      {lines?.edges.length
        ? lines?.edges.map((edge: any) => {
            const isFreeItem = freeGiftArray?.findIndex(item =>{
              if (item.product.id == edge.node.merchandise.id) {
                return true
              }
            })
            return (
              <LineItem
                isUpdating={edge.node.id === isUpdatingLineItem}
                key={edge.node.id}
                isFreeItem = {isFreeItem != -1}
                {...(edge.node as CartLine)}
              />
            )
          })
        : renderEmpty()}
    </Box>
  )
}

export default CartLineItems
