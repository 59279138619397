import { Themed } from '@theme-ui/mdx'

export const Styled = {
  p: Themed.p,
  h1: Themed.h1,
  h2: Themed.h2,
  h3: Themed.h3,
  h4: Themed.h4,
  h5: Themed.h5,
  h6: Themed.h6,
  a: Themed.a,
}
