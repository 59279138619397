/** @jsxImportSource theme-ui */
import React from 'react'

// @ts-ignore
import logo from './logo.svg'
// @ts-ignore
import whiteLogo from './logo-white.svg'
// @ts-ignore
// import whiteLogo from './white.png'
// @ts-ignore
import { useResponsiveValue } from '@theme-ui/match-media'
import Image from 'next/image'
// import Image from 'gatsby-image'

const Logo = ({
  white = [],
  loading = 'lazy',
  priority = false,
  ...props
}: {
  white?: boolean[]
  loading: string
  priority: boolean
}) => {
  const isWhite = useResponsiveValue(white)

  return (
    <Image
      sx={{
        variant: 'styles.logo',
        // height: HeaderHeight.map(h => `${h}px`),
        height: 40,
        objectFit: 'contain',
      }}
      {...props}
      src={isWhite ? whiteLogo : logo}
      alt={`Penguin CBD Logo Black`}
    />
  )
}

export default React.memo(Logo)
