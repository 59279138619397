import Image from "next/image";
import { ComponentProps } from "react";
import { contentfulLoader } from "@delicious-simplicity/next-image-contentful-loader";

export function ContentfulImage(
  props: ComponentProps<typeof Image> & {
    contentful?: Parameters<typeof contentfulLoader>[1];
  }
) {
  return (
    <Image
      {...props}
      alt={props.alt}
      loader={(image) => contentfulLoader(image, {...props.contentful, fm:"webp"})}
    />
  );
}
