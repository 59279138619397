/** @jsxImportSource theme-ui */
import { Flex, Spinner } from 'theme-ui'

type Props = {}

export function LoadingOverlay(props: Props) {
  const {} = props

  return (
    <Flex
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: '#ffffff50',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </Flex>
  )
}
