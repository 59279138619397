/** @jsxImportSource theme-ui */
import { useRouter } from 'next/router'
import { Button, Flex, Input, Text } from 'theme-ui'
import { Styled } from '~/components/styled'
import { useEmailSubscribe } from '~/hooks/use-email-subscribe'

type Props = {
  subscribeText?: string
  buttonText?: string
  backgroundColor?: {
    colorArray?: string[]
  }
  textColor?: { colorArray?: string[] }
  successText?: string
}

export const defaultHeaderProps = {
  subscribeText: 'Sign up today and save 15%',
  buttonText: "I'm In",
  backgroundColor: {
    colorArray: ['gray'],
  },
  textColor: {
    colorArray: ['background'],
  },
  successText: 'Successfully subscribed',
} satisfies Partial<Props>

export function SubscribeSection(props: Props) {
  const {
    subscribeText = defaultHeaderProps.subscribeText,
    buttonText = defaultHeaderProps.buttonText,
    backgroundColor = defaultHeaderProps.backgroundColor,
    textColor = defaultHeaderProps.textColor,
    successText = defaultHeaderProps.successText,
  } = props;

  const router = useRouter();

  const isProductPage = router.asPath.startsWith("/products/");
  
  const { onSubmit, email, loading, setEmail, networkError, ready } =
  useEmailSubscribe()
  return (
    <Flex
      sx={{
        paddingY: 2,
        bg: isProductPage?backgroundColor?.colorArray:"#19B4EB",
        color: textColor?.colorArray,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!ready ? (
        <Flex
          sx={{
            alignItems: 'center',
            gap: ["5px", 20, 40],
            display: ['flex'],
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          <Styled.h2
            sx={{
              m:0,
              color: "background",
              fontSize: [4,5],
              fontWeight: 700
            }}
          >
            {subscribeText}
          </Styled.h2>
          <Flex sx={{ padding: 1, background: 'background', height: "50px" }}>
            <Input
              sx={{
                width: "100%",
                color: 'text',
                border: 'none',
                outline: 'none',
                fontSize: "16px"
              }}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="primary"
              sx={{
                width: [100, 200],
                paddingX: "10px",
                paddingY: 0,
                borderColor: "#19B4EB",
                borderRadius: 0,
                background:"#19B4EB",
              }}
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? 'Loading...' : networkError ? 'Try again' : buttonText}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Text
          sx={{
            color: textColor,
            fontSize: [3,4,5],
          }}
        >
          {successText}
        </Text>
      )}
    </Flex>
  )
}
