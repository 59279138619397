'use client';

import type { Customer } from '@shopify/hydrogen-react/storefront-api-types';
import useSWR from 'swr';
import { createContext, useContext } from 'react';
import { useSession } from 'next-auth/react';
import { getPublicTokenHeaders, getStorefrontApiUrl } from '~/utils/shopify-public-client';

interface Context {
  account?: Customer | null;
  setAccount?: any;
  isLoading?: boolean;
  mutate?: any;
}

const defaultContext: Context = {
  account: null,
  setAccount: (data: any) => data,
};

const AccountContext = createContext(defaultContext);

export function AccountProvider({ children }: { children: any }) {
  const { data: session } = useSession();

  const {
    data: account,
    isLoading,
    error,
    mutate,
  } = useSWR<any>(session?.user, async (user: any) => {
    let customer: Customer | null = null;

    try {
      const res = await fetch(getStorefrontApiUrl(), {
        headers: getPublicTokenHeaders(),
        method: 'POST',
        body: JSON.stringify({
          variables: { customerAccessToken: user.customerAccessToken },
          query: `
            query getCustomer($customerAccessToken: String!) {
              customer(customerAccessToken: $customerAccessToken) {
                id
                firstName
                lastName
                displayName
                email
                phone
                orders (first: 25) {
                  edges {
                    node {
                      id
                      name
                      processedAt
                      totalShippingPrice {
                        amount
                        currencyCode
                      }
                      totalPrice {
                        amount
                        currencyCode
                      }
                      currentTotalTax {
                        amount
                        currencyCode
                      }
                      discountApplications (first: 10) {
                        edges {
                          node {
                            __typename
                            value {
                              ... on MoneyV2 {
                                __typename
                                amount
                                currencyCode
                              }
                              ... on PricingPercentageValue {
                                percentage
                              }
                            }
                          }
                        }
                      }
                      lineItems (first: 50) {
                        edges {
                          node {
                            title
                            quantity
                            customAttributes {
                              key
                              value
                            }
                            discountedTotalPrice {
                              amount
                              currencyCode
                            }
                            originalTotalPrice {
                              amount
                              currencyCode
                            }
                            variant {
                              id
                              title
                              image {
                                filename: url
                                width
                                height
                              }
                              product {
                                handle
                              }
                            }
                          }
                        }   
                      }
                    }
                  }
                }
              }
            }
          `,
        }),
      });

      const data = await res.json();
      customer = { ...data.data.customer };
    } catch (error) {
      console.warn(error);
    }

    return customer;
  });

  const value = { ...defaultContext, account, isLoading, mutate };

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
}

export function useAccountContext() {
  return useContext(AccountContext);
}
