export const ShopName = 'Penguin CBD'

export const AccountScreens = ['']

export const GTM_EVENTS = {
  'viewed-cart': 'Cart Viewed',
  'added-cart': 'Product Added To Cart',
  'removed-cart': 'Product Removed From Cart',
  'updated-cart': 'Updated Product Quantity from Cart',
  'viewed-product': 'Product Viewed',
}

export const BEST_SELLERS = [
  "cbd-oil",
  "cbd-gummies"
]