import React, { useContext } from 'react'
import Client, { buildClient, Cart, Checkout } from 'shopify-buy'

export type StoreContextType = {
  client: ReturnType<typeof buildClient>
  cart: Cart | null
  addVariantToCart: ({
    variantId,
    quantity,
    sellingPlanId,
    isAddingMainProduct,
    isAddingPairProduct,
  }: {
    variantId: string
    quantity: number
    sellingPlanId?: string | null
    isAddingMainProduct?: boolean
    isAddingPairProduct?: boolean
  }) => void
  openCheckout: () => void
  removeLineItem: (lineItemID: string) => void
  updateLineItem: (
    lineItemID: string,
    lineMerchandiseId: string,
    quantity: number
  ) => void
  /**
   * ID of a line item being updated, or null.
   */
  isUpdatingLineItem: string | null
  openingCheckout: boolean
  lineItemJustAddedToCart: ShopifyBuy.CartLine | null
  clearLineItemJustAddedToCart: () => void
  addDiscount: (code: string) => void
  addMultipleVariantsToCart: (
    lineItems: { variantId: string; quantity: number }[]
  ) => Promise<any>
  chargifyDiscountCode?: string
  setChargifyDiscountCode: (code: string) => void
  isAddingMainProduct: boolean
  isAddingPairProduct: boolean
}

// @ts-ignore
const StoreContext = React.createContext<StoreContextType>()

export default StoreContext
