/** @jsxImportSource theme-ui */
import { jsx, Box, Badge } from 'theme-ui'
import { memo, useContext } from 'react'
import { useCartModal } from 'src/context/UIContext'
import { MdShoppingCart } from 'react-icons/md'
import { animated } from 'react-spring'
import { useTheme } from 'src/hooks/use-theme'
import StoreContext from 'src/context/StoreContext'

type Props = {
  color?: string
}

function OpenCartButton({ color }: Props) {
  const { toggleCartIsOpen } = useCartModal()
  const { cart } = useContext(StoreContext)

  const renderNewItemPopUp = () => {
    return null
    // return transitions.map(({ item, key, props: style }) => {
    //   if (!item) return null

    //   return (
    //     <Box
    //       key={key}
    //       as={animated.div}
    //       style={style}
    //       sx={{
    //         position: 'absolute',
    //         top: 25,
    //         right: 0,
    //         pointerEvents: 'none',
    //       }}
    //       onClick={e => e.stopPropagation()}
    //     >
    //       {!!lineItem.variant?.image.src && (
    //         <img
    //           sx={{
    //             height: 75,
    //             width: 75,
    //             objectFit: 'cover',
    //             borderRadius: 1,
    //             overflow: 'hidden',
    //           }}
    //           alt="Product Added To Cart"
    //           src={lineItem.variant?.image.src}
    //         />
    //       )}
    //     </Box>
    //   )
    // })
  }

  const renderCountBadge = () => {
    if (!cart?.totalQuantity) return null

    return (
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: 'absolute',
          top: `-5px`,
          right: '5px',
          pointerEvents: 'none',
        }}
      >
        <Badge
          aria-roledescription="Number of cart items"
          // color={colors?.secondary}
          bg="accent"
          sx={{
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            verticalAlign: 'middle',
            paddingTop: '.2em',
            lineHeight: '1.2em',
          }}
        >
          {cart?.totalQuantity}
        </Badge>
      </Box>
    )
  }

  // return renderNewItemPopUpNew()

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        as="button"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          appearance: 'none',
          bg: 'transparent',
          border: 'none',
          px: 3,
          outline: 'none',
        }}
        onClick={() => {
          // alert('cart opened')
          toggleCartIsOpen(true)
        }}
        aria-label="Shopping Cart"
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          // style={cartSpring}
          as={animated.div}
        >
          <MdShoppingCart
            aria-label="Shopping Cart Icon"
            size={25}
            color={color}
          />
        </Box>
      </Box>
      {renderNewItemPopUp()}
      {renderCountBadge()}
    </Box>
  )
}

export default memo(OpenCartButton)
